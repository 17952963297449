import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {
    Expander,
    ExpanderItem,
    Flash,
    Form,
    FormField as Field,
    Header,
    Modal,
    PegasusClient,
    ScrollMarker,
    utils,
} from '@brandedholdings/react-components';

import Footer from './Footer';

import '@brandedholdings/react-components/stylesheets/global.scss';

import Prepop from '../models/PrepopulatedFields';
import { siteMeta } from '../data/site_meta.yml';

const { Log } = utils;

const pegasusClient = new PegasusClient(siteMeta);

export default class DefaultPageWrapper extends React.Component {

    static propTypes = {
        ppc: PropTypes.bool,
        templateType: PropTypes.node,
    };

    static openAboutUsModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'About Us', true);
        DefaultPageWrapper._aboutUsModal.openModal();
    };

    static openFaqModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'FAQ', true);
        DefaultPageWrapper._faqModal.openModal();
    };

    static openContactUsModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'Contact Us', true);
        DefaultPageWrapper._contactUsModal.openModal();
    };

    static openConsumerAdvisoryModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'Consumer Advisory', true);
        DefaultPageWrapper._consumerAdvisoryModal.openModal();
    };

    static openUnsubscribeModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'Unsubscribe', true);
        DefaultPageWrapper._unsubscribeModal.openModal();
    };

    static openPartnerSignUpModal = (e) => {
        e.preventDefault();
        DefaultPageWrapper.closeModals();
        Log.toAnalyticsEvent('Modal', 'Open', 'Partner Signup', true);
        DefaultPageWrapper._partnerSignUpModal.openModal();
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            messages: {},
        };
    }

    onUnsubscribeFormSubmit = (e, data) => {
        e.preventDefault();

        const email = data.email || '';
        const indexAt = email.indexOf('@');
        const indexDot = email.lastIndexOf('.');
        const isValidEmail = email && indexAt !== -1 && indexDot !== -1 && indexDot > indexAt;

        this.setState({
            errors: {
                unsubscribe: isValidEmail ? false : 'Please enter a valid email address.',
            },
            messages: {
                unsubscribe: false,
            },
        });


        if (isValidEmail) {
            Log.toAnalyticsEvent('Form', 'Submit', 'Unsubscribe', true);

            pegasusClient.postUnsubscribe(email)
                .then((response) => {
                    Log.debug('unsub response', response);

                    if (response.data.success) {
                        this.setState({
                            messages: {
                                unsubscribe: 'You have been unsubscribed.',
                            },
                        });
                    }
                }).catch((err) => {
                    Log.toRollbar(`Email unsubscribe error: ${err}`);
                });
        }
    };

    focusPersonalLoanForm(e) {
        const formScrollMarker = 'heroStart';

        if (ScrollMarker.exists(formScrollMarker)) {
            e.preventDefault();
            ScrollMarker.scrollToMarker(formScrollMarker);
        }
    }

    static closeModals = () => {
        DefaultPageWrapper._aboutUsModal.state.modalIsOpen && DefaultPageWrapper._aboutUsModal.closeModal();
        DefaultPageWrapper._contactUsModal.state.modalIsOpen && DefaultPageWrapper._contactUsModal.closeModal();
        DefaultPageWrapper._consumerAdvisoryModal.state.modalIsOpen && DefaultPageWrapper._consumerAdvisoryModal.closeModal();
        DefaultPageWrapper._unsubscribeModal.state.modalIsOpen && DefaultPageWrapper._unsubscribeModal.closeModal();
        DefaultPageWrapper._partnerSignUpModal.state.modalIsOpen && DefaultPageWrapper._partnerSignUpModal.closeModal();
        DefaultPageWrapper._faqModal.state.modalIsOpen && DefaultPageWrapper._faqModal.closeModal();
    };

    componentDidMount = () => {
        Prepop.collate();
        const { ref } = this.props;

        if (ref instanceof Function) { ref(this); }
    };

    render() {
        const { templateType } = this.props;
        const environment = siteMeta.environment;
        const testBar = ['test', 'dev'].includes(environment) ? (
            <Flash level="error" center>
                <strong>Note:</strong>
                {' '}
                This is a FinanceMatrix
                {' '}
                { environment === 'test' ? 'testing' : 'development' }
                {' '}
                environment.
            </Flash>
        ) : '';

        const today = new Date();
        const copyrightName = siteMeta.footerCopyrightName || siteMeta.nameLegal;

        const template = templateType;
        let hideNavigation = false;

        if (template === 'hideNavigation') {
            hideNavigation = true;
        }

        const ppcShow = this.props.ppc || false;

        return (
            <div>
                <Helmet
                    title={siteMeta.title}
                    meta={[
                        { name: 'description', content: siteMeta.description },
                        { name: 'format-detection', content: 'telephone-no' },
                        { name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1, user-scalable=yes' },
                    ]}
                    link={[
                        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700' },
                        { rel: 'manifest', href: `${siteMeta.url}/manifest.json` },
                    ]}
                >
                    <html lang="en" />
                </Helmet>
                { testBar }
                <div className="body">

                    <Header
                        siteMeta={siteMeta}
                        center={!hideNavigation ? null : true}
                        hidePhoneNumber
                        hideSearch
                    >
                        { !hideNavigation ? (
                            <div>
                                <nav className="nav">
                                    <ul className="nav__items">
                                        <li className="nav__item">
                                            <button className="nav__link" onClick={DefaultPageWrapper.openAboutUsModal}>
                                                About Us
                                            </button>
                                        </li>
                                        <li className="nav__item">
                                            <button className="nav__link" onClick={DefaultPageWrapper.openFaqModal}>
                                                FAQs
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        ) : ('')}
                    </Header>


                    {this.props.children}

                    { !hideNavigation ? (
                        <Footer
                            useAnchor="true"
                            siteMeta={siteMeta}
                            trustIcons={null}
                            sidebar={false}
                            omitSitemap
                            sections={[
                                {
                                    heading: 'Get Started',
                                    links: [
                                        {
                                            name: 'See Your Options',
                                            href: '/v2/get-started/',
                                        },
                                        {
                                            name: 'About Us',
                                            href: '#',
                                            onClick: DefaultPageWrapper.openAboutUsModal,
                                        },
                                        {
                                            name: 'FAQs',
                                            href: '#',
                                            onClick: DefaultPageWrapper.openFaqModal,
                                        },
                                        {
                                            name: 'Contact Us',
                                            href: '#',
                                            onClick: DefaultPageWrapper.openContactUsModal,
                                        },
                                    ],
                                },
                                {
                                    heading: 'Support',
                                    links: [
                                        {
                                            name: 'Consumer Advisory',
                                            href: '#',
                                            onClick: DefaultPageWrapper.openConsumerAdvisoryModal,
                                        },
                                        {
                                            name: 'Privacy Policy',
                                            href: '/privacy-policy',
                                        },
                                        {
                                            name: 'Terms & Conditions',
                                            href: '/terms',
                                        },
                                        {
                                            name: 'Do Not Sell My Personal Information',
                                            href: '/privacy-policy#opt-out-rights',
                                        },
                                        {
                                            name: 'Unsubscribe',
                                            href: '/unsubscribe',
                                        },
                                    ],
                                },
                            ]}
                            disclaimer={false}
                            excludeLegalLinks
                        />
                    ) : ('')}
                    { !hideNavigation ? (
                        <footer className="footer footer--light footer--sub">
                            { ppcShow ? (
                                <div>
                                    <div className="footer__content">
                                        <h5>Our Top Lending Providers</h5>

                                        <p>
    We know that life is hectic and not everyone has the time to research all of the
                                            loan options available. Because of that fact, we've taken the time to review and
                                            evaluate some of the best and biggest lenders in personal loans. Keep reading to
                                            learn more about these lending options, as well as more information that can
                                            help you borrow responsibly, and with confidence.

                                        </p>
                                    </div>

                                    <div className="footer__content">
                                        <div className="footer__body">
                                            <div className="footer__item">
                                                <p>
                                                    <strong>Primerates.com</strong>
                                                </p>

                                                <ul className="list">
                                                    <li>Loan amount: $5,000 - $100,000</li>
                                                    <li>APR range: 6% - 36%</li>
                                                    <li>Loan term: 24 - 84 months</li>
                                                    <li>Minimum credit score: fair and above</li>
                                                    <li>Best feature: over 15 lenders to choose from</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="footer__sidebar">
                                            <img src="/assets/images/logos/logo-primerates.png" alt="Primerates.com" className="footer--logo__primerates" />
                                        </div>
                                    </div>
                                    <div className="footer__content">
                                        <div className="footer__body">
                                            <div className="footer__item">
                                                <p>
                                                    <strong>CreditLoanCompare.com</strong>
                                                </p>

                                                <ul className="list">
                                                    <li>Loan amount: Up to $5,000</li>
                                                    <li>APR range: Up to 36%</li>
                                                    <li>Loan term: 61 days - 84 months</li>
                                                    <li>Minimum credit score: poor and above</li>
                                                    <li>
    Best feature: Lending options for people with less than perfect
                                                        credit
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="footer__sidebar">
                                            <img src="/assets/images/logos/logo-creditloancompare.png" alt="CreditLoanCompare.com" className="footer--logo__creditloancompare" />
                                        </div>
                                    </div>
                                    <div className="footer__content">
                                        <h5>Types of Personal Loans</h5>

                                        <p>
    Personal loans come in a variety of flavors to suit borrowers and their lending
                                            needs. Personal loan needs can range from a few hundred dollars for an emergency
                                            expense, to high-dollar loans to remodel your home or other large purchases.
                                            Some of the popular types are:

                                        </p>

                                        <ul className="list">
                                            <li>
    Fixed rate – as the name implies, the interest rate for this loan stays
                                                fixed for the life of the loan. This allows for more predictability when
                                                planning for repayments
                                            </li>

                                            <li>
    Variable rate – as this name also implies, the interest rate will vary for
                                                these types of loans based on market fluctuations. Be careful, you might get
                                                a lower rate to start, but unpredictable markets can make repayment plans
                                                more unpredictable.
                                            </li>

                                            <li>
    Peer-to-peer – in an attempt to lower overhead costs and speed up the
                                                process, this type of lending links individuals looking for loans directly
                                                to the lenders. Services like this include LendingClub.
                                            </li>
                                        </ul>

                                        <h5>Personal Loan APR and Repayment</h5>

                                        <p>
    The actual APR for personal loans will vary based on the lender you decide to
                                            work with. These loans range from 6% - 36%—making them a much better choice than
                                            many short-term or payday loans. The terms make personal loans a popular choice
                                            among those consolidating their debt or even folks who are looking to finance a
                                            large purchase without the need of a credit card. Any way you decide, always be
                                            sure to borrow responsibly.

                                        </p>

                                        <p>
    Full repayment for the loans shown range between 61 days to 84 months. APR rates
                                            listed will include all associated fees.

                                        </p>

                                        <p>
    Representative loan example: a borrower with average credit, who borrows $17,000
                                            for a 3 year term at 19.79% interest rate with a 3% origination fee. The
                                            borrower would receive $16,490 in proceeds ($17,000 minus the $510 origination
                                            fee), and would make 36 monthly payments of $629.96. This equals an APR of
                                            22.02% and total loan payments of $22,678.68.

                                        </p>

                                        <h5>Interest Rates Explained</h5>

                                        <p>
    The most important part of borrowing is the interest rate charged. It can add a
                                            substantial amount to your overall repayment amount. Each percentage point adds
                                            to the overall amount. For example, if you take a loan out for $10,000 with an
                                            interest rate of 5%, you will end up paying $10,500 in total. If you get an
                                            interest of 10% though, you'll be paying a total of $11,000.

                                        </p>

                                        <h5>How Interest Rates Are Calculated</h5>

                                        <p>
    Interest rates are based on loan types and the quality of the borrower. Fixed
                                            versus variable loans can also affect the rates as they will fluctuate through
                                            the life of the loan. Repayment term length and amount borrowed will also affect
                                            rates.

                                        </p>

                                        <p>
    Another major influencing factor is the borrower's current financial status, as
                                            well as history. Credit scores, current salary, and any outstanding debts will
                                            also greatly affect rates and loan terms.

                                        </p>

                                        <h5>Personal Loan Providers: How to Choose</h5>

                                        <p>
    In order to borrow responsibly, you should first determine your exact borrowing
                                            needs and find a lender who can match those need with the best rate possible.
                                            Some key items to watch for when searching for loans are:

                                        </p>

                                        <ul className="list">
                                            <li>
    Maximum loan amount – some providers can offer loans up to $20,000, while
                                                others as high $100,000. Be sure the lender can provide you with the amount
                                                you're looking for.
                                            </li>

                                            <li>
    APR – as explained above, this number can greatly affect the overall cost of
                                                your loan and can vary greatly between lenders.
                                            </li>

                                            <li>
    Loan term – it's important to make sure you can repay your loan on time, so
                                                be sure to watch loan terms carefully. A borrower's financial state can
                                                change quickly, so be sure you plan ahead and understand your loan terms.
                                            </li>

                                            <li>
    Borrower qualifications – while some lenders might be forgiving, many will
                                                require excellent credit to get a loan. Some lenders may also verify your
                                                employment or income, so be sure that a lender can meet your needs before
                                                working with them.
                                            </li>

                                            <li>
    Speed – a major advantage that online lenders have over traditional methods
                                                is speed and ease of use. Less bureaucracy and paperwork means a faster and
                                                easier borrowing process overall.
                                            </li>

                                        </ul>

                                        <p>
    Online loan marketplaces are a popular choice for many borrowers. The marketplace
                                            model allows multiple lenders to compete for a borrower—which gives users more
                                            options and help keeps fees lower.

                                        </p>

                                        <h5>How To Get Started</h5>

                                        <p>
    Working with online lenders is easy. Normally, lenders will require a minimum
                                            amount of information from a borrower:

                                        </p>

                                        <ul className="list">
                                            <li>How much is the loan for?</li>

                                            <li>What will the loan be used for?</li>

                                            <li>Personal contact info such as phone number or email</li>

                                            <li>
    Once lenders have this info, they will often run a soft credit pull. This
                                                allows lenders to gauge your credit worthiness in a way that will not affect
                                                your credit score. Based on your credit score and other points, lenders will
                                                determine how much you qualify for, as well as APR and repayment terms.
                                            </li>

                                        </ul>

                                        <p>
    Loan marketplaces will have potential borrowers submit their information one
                                            time. This can then pre-qualify them for a number of loan offers from lenders.
                                            Approval usually takes only minutes, and if you are approved, you will be shown
                                            options that match your loan criteria and credit worthiness. Once you choose the
                                            option that best suits your needs, you can work directly with that lender to
                                            complete the process. During this process, they might request some verification
                                            documents. The often include:

                                        </p>

                                        <ul className="list">
                                            <li>Personal ID – such as driver’s license or passport</li>

                                            <li>
    Proof of residence – utility bills, mortgage statement, or rental
                                                agreement
                                            </li>

                                            <li>
    Income verification – recent pay stubs or bank statements might be required
                                                to verify income
                                            </li>
                                        </ul>


                                        <p>
    With most online lenders, the entire loan process can be completed in a matter of
                                            days, or weeks at most.

                                        </p>

                                        <hr />

                                        <p>
                                            <strong>Advertiser Disclosure</strong>
                                        </p>

                                        <p>
                                            <small>
                                                Our site is a free resource that allows visitors to learn about, review,
                                                and compare features. Please note that the operator of this site may accept
                                                advertising compensation from companies that are displayed on the site. This
                                                compensation can also impact the ranking and presentation of products
                                                displayed on our site. Ratings on our site are determined in a subjective
                                                fashion and is based on factors such as brand popularity, reputation,
                                                conversion rates, compensation rates paid to us as well as general consumer
                                                interest. The listings below DO NOT imply an endorsement. Except as
                                                expressly outlined in our terms of use, all warranties and representations
                                                pertaining to information on this page have been disclaimed. All information
                                                shown, including pricing is subject to change at any time.
                                            </small>
                                        </p>

                                        <br />

                                    </div>
                                </div>
                            ) : null}

                            <div>
                                <div className="footer__content">
                                    <div className="footer__body">
                                        <div className="footer__item">
                                            <address>
                                                <div>{ siteMeta.address.name }</div>
                                                <div>{ siteMeta.address.street }</div>
                                                { siteMeta.address.street2 ? (
                                                    <div>{ siteMeta.address.street2 }</div>
                                                ) : '' }
                                                <div>
                                                    { siteMeta.address.city }
                                                ,
                                                    {' '}
                                                    { siteMeta.address.state }
                                                    {' '}
                                                    { siteMeta.address.zip }
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                        <div className="footer__sidebar">
                                        <div className="footer__copyright">
                                        &copy;
                                            { today.getFullYear() }
                                            {' '}
                                            { copyrightName }
                                        </div>
                                    </div>
                                    </div>
                                <div className="footer__content">
                                    <div className="footer__body">
                                        <div className="footer__item">
                                            <div className="footer__disclaimer">
                                                <p>
                                                THE OPERATOR OF THIS WEBSITE IS NOT A LENDER, does not broker loans to
                                                lenders and does not make/fund any product offerings, loans or credit
                                                decisions. This Web Site does not constitute an offer or solicitation to
                                                lend. This site will securely submit the information provided to a lender.
                                                Providing your information on this Website does not guarantee approval for a
                                                product offering. Finance-Matrix.com may change from time to time without
                                                notice. For details, questions or concerns regarding your application,
                                                please contact your lender directly. Lenders will at their discretion
                                                perform credit checks with the three credit reporting bureaus: Experian,
                                                Equifax, TransUnion, or through alternative providers. The informational
                                                content contained on or linked to this site is for convenience only and
                                                Finance-Matrix.com does not guarantee the accuracy of such information.
                                                Because all financial decisions must be fact dependent and based on your
                                                individual circumstances you should consult with a financial professional
                                                before making any financial decisions. Finance-Matrix.com shall not be
                                                responsible for any financial actions taken by you in response to the
                                                content of this site.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </footer>
                    ) : ('')}


                    <Modal
                        heading="About Us"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._aboutUsModal = ref}
                    >
                        <p>
Finance Matrix connects consumers with financing options that work for them. Finding
                            trustworthy financial assistance is easier said than done. That’s where we come in. We
                            gather your information and present you with the best financial offers we can find, all in
                            one location.

                        </p>
                        <p>
At Finance Matrix, we reach out to our expansive network of partners to help you find
                            personal loans, installment loans, auto loans, and other products like credit cards, lines
                            of credit, and even business loans. With our help, you can find the financial solution
                            you’re looking for.

                        </p>
                    </Modal>


                    <Modal
                        heading="Frequently Asked Questions"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._faqModal = ref}
                    >
                        <Expander altStyle={false}>
                            <ExpanderItem title={siteMeta.content.faqs.item1.question}>
                                <p>{ siteMeta.content.faqs.item1.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item2.question}>
                                <p>{ siteMeta.content.faqs.item2.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item3.question}>
                                <p>{ siteMeta.content.faqs.item3.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item4.question}>
                                <p>{ siteMeta.content.faqs.item4.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item5.question}>
                                <p>{ siteMeta.content.faqs.item5.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item6.question}>
                                <p>{ siteMeta.content.faqs.item6.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item7.question}>
                                <p>{ siteMeta.content.faqs.item7.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item8.question}>
                                <p>{ siteMeta.content.faqs.item8.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item9.question}>
                                <p>{ siteMeta.content.faqs.item9.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item10.question}>
                                <p>{ siteMeta.content.faqs.item10.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item11.question}>
                                <p>{ siteMeta.content.faqs.item11.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item12.question}>
                                <p>{ siteMeta.content.faqs.item12.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item13.question}>
                                <p>{ siteMeta.content.faqs.item13.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item14.question}>
                                <p>{ siteMeta.content.faqs.item14.answer }</p>
                            </ExpanderItem>
                            <ExpanderItem title={siteMeta.content.faqs.item15.question}>
                                <p>{ siteMeta.content.faqs.item15.answer }</p>
                            </ExpanderItem>
                        </Expander>
                    </Modal>


                    <Modal
                        heading="Contact Us"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._contactUsModal = ref}
                    >
                        <h4>Contact us at:</h4>
                        <p>
                            <address>
                                <div>{ siteMeta.address.name }</div>
                                <div>{ siteMeta.address.street }</div>
                                { siteMeta.address.street2 ? (
                                    <div>{ siteMeta.address.street2 }</div>
                                ) : '' }
                                <div>
                                    { siteMeta.address.city }
,
                                    {' '}
                                    { siteMeta.address.state }
                                    {' '}
                                    { siteMeta.address.zip }
                                </div>
                            </address>
                        </p>
                        <h4>Email us at:</h4>
                        <p><a href={`mailto:${ siteMeta.email}`}>{ siteMeta.email }</a></p>

                    </Modal>


                    <Modal
                        heading="Consumer Advisory"
                        subheading="August 2018"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._consumerAdvisoryModal = ref}
                    >
                        <h4>Forged Emails and Impersonator Phone Calls</h4>
                        <p>
Consumers have been receiving emails that look like they are from Finance Matrix confirming
                            that the recipient has been approved for a loan amount between $10,000 and $15,000. There
                            are contact phone numbers for alleged employees of Finance Matrix. These alleged employees
                            collect personal information about the consumer over the phone and require a pre-paid card,
                            a Western Union MoneyGram, or a Cashier’s Check to be sent to them to insure the loan. Once
                            the insurance is received, the alleged employee cannot be contacted.

                        </p>
                        <p>
Consumers have also received phone calls from someone representing themselves as being an
                            employee of Finance Matrix whereby they take a consumer’s information over the phone to
                            apply for a loan. The caller asks for a pre-paid card to be sent to them to insure the loan.
                            Once the pre-paid card is received, the caller cannot be contacted again.

                        </p>
                        <p>
Finance Matrix will not contact consumers to collect personal information over the phone.
                            Lenders in the Finance Matrix Online Marketplace will never require a payment of any kind
                            prior to receiving a loan disbursement. Finance Matrix services are free to consumers.

                        </p>

                        <h4>Deceptive Ads and Sites Discovered</h4>
                        <p>
Be aware that organizations are creating fake ads and sites to lure consumers to
                            “look-a-like” Finance Matrix web sites. They may include fake security icons and look like
                            legitimate short-term loans sites. These sites may deceptively be collecting your personal
                            information. Victims have been receiving harassing phone calls or emails demanding money,
                            and may even see funds illegally withdrawn from their bank accounts.

                        </p>
                        <p>
Finance Matrix will never contact consumers to collect funds, nor will Finance Matrix remove
                            funds from your account as Finance Matrix is not a lender. Finance Matrix services are free
                            for all consumers.

                        </p>

                        <h4>What You Need to Know</h4>
                        <p>
The security of your information is important to Finance Matrix which is a proud member of
                            the Online Lenders Alliance (www.onlinelendersalliance.org). OLA is proud to announce the
                            launch of the OLA Consumer Hotline at 1-866-299-7585. The Consumer Hotline is a resource for
                            online customers to speak to live operators to answer questions about online short-term
                            lending and report fraudulent phone calls.

                        </p>
                        <p>
Finance Matrix works closely with search engines to identify and eliminate counterfeit ads
                            and web sites. However, always make sure you are on the right site before entering your
                            information. Be cautious with callers claiming to be from Finance Matrix. If you did not
                            initiate the contact with the caller and they are asking for your personal information, it
                            is most likely fraud. Avoid becoming a victim of fraud by going to the official Finance
                            Matrix web site.

                        </p>

                        <p>
If you feel you have been a victim of fraud, please contact your local authorities or click
                            on one of these links below:

                        </p>
                        <ul className="list">
                            <li>
                                <a href="http://www.fbi.gov/scams-safety/e-scams">http://www.fbi.gov/scams-safety/e-scams</a>
                            </li>
                            <li>
                                <a href="http://www.ic3.gov/media/2010/101201.aspx">http://www.ic3.gov/media/2010/101201.aspx</a>
                            </li>
                        </ul>
                        <p>
To notify Finance Matrix of an identity theft please email
                            {' '}
                            <a
                                href={`mailto:${  siteMeta.tipEmail}`}
                                                        >
                                                                                            { siteMeta.tipEmail }

                                                                                                                                            </a>
.

                        </p>
                    </Modal>


                    

                    <Modal
                        heading="IMPORTANT: LEGAL TERMS OF WEBSITE USE"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._termsModal = ref}
                    >


                    </Modal>


                    <Modal
                        heading="Unsubscribe"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._unsubscribeModal = ref}
                    >
                        <Form method="POST" onSubmit={this.onUnsubscribeFormSubmit}>
                            { this.state.errors.unsubscribe
                                ? <Flash level="error">{ this.state.errors.unsubscribe }</Flash>
                                : null }
                            { this.state.messages.unsubscribe
                                ? <Flash level="success">{ this.state.messages.unsubscribe }</Flash>
                                : null }
                            <p>Enter your email address to unsubscribe from Finance Matrix loan offers.</p>
                            <Field
                                type="text"
                                name="email"
                                id="email"
                                placeholder="you@example.com"
                            />
                            <button
                                type="submit"
                                className="button button--success button--big"
                            >
                                Unsubscribe Me
                            </button>
                        </Form>
                    </Modal>


                    <Modal
                        heading="Become a Partner"
                        triggerOnClick
                        ref={ref => DefaultPageWrapper._partnerSignUpModal = ref}
                    >
                        <p>Add zoho form here</p>
                    </Modal>


                </div>
            </div>
        );
    }
}
