import string from "underscore.string";

/**
 * Encapsulating page data
 */
class PagePost {
    constructor(pageMeta, siteMeta) {
        this._pageMeta = pageMeta;
        this._siteMeta = siteMeta;
    }

    get description() {
        return this._pageMeta.description;
    }

    get excerpt() {
        return null;
    }

    get link() {
        return this._pageMeta.link;
    }

    get seoDescription() {
        return string.stripTags(this.description);
    }

    get seoFeaturedImage() {
        return null;
    }

    get title() {
        return this._pageMeta.title;
    }

    get truncatedExcerpt() {
        return null;
    }

    get robots() {
        return this._pageMeta.robots;
    }
}

export default PagePost;
