import * as queryString from '@brandedholdings/query-string';

export default class PrepopulatedFields {
    static STORAGE_KEY = 'peg_prepop';

    static queryToFieldMap = {
        primary_phone: 'primaryPhone',
        address_zip: 'homeAddress.zip',
        credit_rating_id: 'creditRating.id',
        loan_reason_id: 'loanReason.id',
        total_debt: 'totalDebt.id',
        business_owner: 'businessOwner',
        vehicle_paid_off: 'vehiclePaidOff.id',
        military: 'military',
        residence_status: 'residenceStatus.id',
        name_first: 'nameFirst',
        name_last: 'nameLast',
        email: 'email'
    };

    static fieldToQueryMap = (map => {
        const result = {};

        for (let prop in map)
            result[map[prop]] = prop;

        return result;
    })(PrepopulatedFields.queryToFieldMap);

    static rawValues = {};

    static save() {
        if (typeof sessionStorage !== 'undefined')
            sessionStorage.setItem(PrepopulatedFields.STORAGE_KEY, JSON.stringify(PrepopulatedFields.rawValues));
    }

    static load() {
        if (typeof sessionStorage !== 'undefined') {
            const storedValues = sessionStorage.getItem(PrepopulatedFields.STORAGE_KEY);

            if (storedValues)
                PrepopulatedFields.rawValues = {
                    ...PrepopulatedFields.rawValues,
                    ...JSON.parse(storedValues)
                };
        }
    }

    static collate() {
        if (typeof document === 'undefined')
            return;
        
        PrepopulatedFields.load();

        const queryParams = queryString.parse(document.location.search);

        for (let paramName in PrepopulatedFields.queryToFieldMap)
            if (paramName in queryParams && queryParams[paramName] !== '')
                PrepopulatedFields.rawValues[paramName] = queryParams[paramName];
        
        PrepopulatedFields.save();
    }

    static mapFields() {
        const { rawValues, queryToFieldMap } = PrepopulatedFields;

        const applicantFields = {};

        for (let queryName in rawValues) {
            applicantFields[queryToFieldMap[queryName]] = rawValues[queryName];
        }

        return applicantFields;
    }
    
    static isPrepopulated(...fieldNames) {
        PrepopulatedFields.collate();

        for (let fieldName of fieldNames)
            if (PrepopulatedFields.fieldToQueryMap[fieldName] in PrepopulatedFields.rawValues === false)
                return false;
        
        return true;
    }
};
