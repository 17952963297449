import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link } from '@brandedholdings/react-components';

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="footer__content">
                        <div className="footer__body">
                            <nav className="footer__item nav-footer">
                                { this.props.sections.map(section => (
                                    <div className="nav-footer__section" key={shortid.generate()}>
                                        <h6 className="nav-footer__heading">{ section.heading }</h6>
                                        <ul>
                                            { section.links.map((link) => {
                                                const typeProps = link.type === 'button'
                                                    ? { className: 'button button--small button--ghost button--ghost--white' }
                                                    : {};

                                                if ('target' in link) {
                                                    typeProps.target = link.target;
                                                }

                                                if ('onClick' in link) {
                                                    typeProps.onClick = link.onClick;
                                                }

                                                return (
                                                    <li key={shortid.generate()}>
                                                        <Link to={link.href} {...typeProps} className={(link.linkModifiers ? link.linkModifiers : '')} dangerouslySetInnerHTML={{ __html: link.name }} />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )) }
                            </nav>
                        </div>
                        <div className="footer__sidebar">
                            <div className="footer__item footer__about">
                                <Link to="/" className="footer__logo footer--logo__finance-matrix" />
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

Footer.contextTypes = {
    siteMeta: PropTypes.object,
};

export default Footer;
